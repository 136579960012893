@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,500,100,100italic,300italic,400italic,500italic,700,700italic,900,900italic');

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto', sans-serif;
}

table, tbody, thead, tfoot, td, th, tr {
  vertical-align: middle;
}

* { box-sizing: border-box }

a { color: #064780; }

input:focus {
    outline: none;
}
