@font-face {
	font-family: 'icomoon';
	src:url('fonts/icomoon.eot?3yz7uu');
	src:url('fonts/icomoon.eot?#iefix3yz7uu') format('embedded-opentype'),
		url('fonts/icomoon.woff?3yz7uu') format('woff'),
		url('fonts/icomoon.ttf?3yz7uu') format('truetype'),
		url('fonts/icomoon.svg?3yz7uu#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="pricon-"], [class*=" pricon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pricon-3d-rotation:before {
	content: "\e600";
}

.pricon-accessibility:before {
	content: "\e601";
}

.pricon-account-balance:before {
	content: "\e602";
}

.pricon-account-balance-wallet:before {
	content: "\e603";
}

.pricon-account-box:before {
	content: "\e604";
}

.pricon-account-child:before {
	content: "\e605";
}

.pricon-account-circle:before {
	content: "\e606";
}

.pricon-add-shopping-cart:before {
	content: "\e607";
}

.pricon-alarm:before {
	content: "\e608";
}

.pricon-alarm-add:before {
	content: "\e609";
}

.pricon-alarm-off:before {
	content: "\e60a";
}

.pricon-alarm-on:before {
	content: "\e60b";
}

.pricon-android:before {
	content: "\e60c";
}

.pricon-announcement:before {
	content: "\e60d";
}

.pricon-aspect-ratio:before {
	content: "\e60e";
}

.pricon-assessment:before {
	content: "\e60f";
}

.pricon-assignment:before {
	content: "\e610";
}

.pricon-assignment-ind:before {
	content: "\e611";
}

.pricon-assignment-late:before {
	content: "\e612";
}

.pricon-assignment-return:before {
	content: "\e613";
}

.pricon-assignment-returned:before {
	content: "\e614";
}

.pricon-assignment-turned-in:before {
	content: "\e615";
}

.pricon-autorenew:before {
	content: "\e616";
}

.pricon-backup:before {
	content: "\e617";
}

.pricon-book:before {
	content: "\e618";
}

.pricon-bookmark:before {
	content: "\e619";
}

.pricon-bookmark-outline:before {
	content: "\e61a";
}

.pricon-bug-report:before {
	content: "\e61b";
}

.pricon-cached:before {
	content: "\e61c";
}

.pricon-class:before {
	content: "\e61d";
}

.pricon-credit-card:before {
	content: "\e61e";
}

.pricon-dashboard:before {
	content: "\e61f";
}

.pricon-delete:before {
	content: "\e620";
}

.pricon-description:before {
	content: "\e621";
}

.pricon-dns:before {
	content: "\e622";
}

.pricon-done:before {
	content: "\e623";
}

.pricon-done-all:before {
	content: "\e624";
}

.pricon-event:before {
	content: "\e625";
}

.pricon-exit-to-app:before {
	content: "\e626";
}

.pricon-explore:before {
	content: "\e627";
}

.pricon-extension:before {
	content: "\e628";
}

.pricon-face-unlock:before {
	content: "\e629";
}

.pricon-favorite:before {
	content: "\e62a";
}

.pricon-favorite-outline:before {
	content: "\e62b";
}

.pricon-find-in-page:before {
	content: "\e62c";
}

.pricon-find-replace:before {
	content: "\e62d";
}

.pricon-flip-to-back:before {
	content: "\e62e";
}

.pricon-flip-to-front:before {
	content: "\e62f";
}

.pricon-get-app:before {
	content: "\e630";
}

.pricon-grade:before {
	content: "\e631";
}

.pricon-group-work:before {
	content: "\e632";
}

.pricon-help:before {
	content: "\e633";
}

.pricon-highlight-remove:before {
	content: "\e634";
}

.pricon-history:before {
	content: "\e635";
}

.pricon-home:before {
	content: "\e636";
}

.pricon-https:before {
	content: "\e637";
}

.pricon-info:before {
	content: "\e638";
}

.pricon-info-outline:before {
	content: "\e639";
}

.pricon-input:before {
	content: "\e63a";
}

.pricon-invert-colors:before {
	content: "\e63b";
}

.pricon-label:before {
	content: "\e63c";
}

.pricon-label-outline:before {
	content: "\e63d";
}

.pricon-language:before {
	content: "\e63e";
}

.pricon-launch:before {
	content: "\e63f";
}

.pricon-list:before {
	content: "\e640";
}

.pricon-lock:before {
	content: "\e641";
}

.pricon-lock-open:before {
	content: "\e642";
}

.pricon-lock-outline:before {
	content: "\e643";
}

.pricon-loyalty:before {
	content: "\e644";
}

.pricon-markunread-mailbox:before {
	content: "\e645";
}

.pricon-note-add:before {
	content: "\e646";
}

.pricon-open-in-browser:before {
	content: "\e647";
}

.pricon-open-in-new:before {
	content: "\e648";
}

.pricon-open-with:before {
	content: "\e649";
}

.pricon-pageview:before {
	content: "\e64a";
}

.pricon-payment:before {
	content: "\e64b";
}

.pricon-perm-camera-m:before {
	content: "\e64c";
}

.pricon-perm-contact-cal:before {
	content: "\e64d";
}

.pricon-perm-data-setting:before {
	content: "\e64e";
}

.pricon-perm-device-info:before {
	content: "\e64f";
}

.pricon-perm-identity:before {
	content: "\e650";
}

.pricon-perm-media:before {
	content: "\e651";
}

.pricon-perm-phone-msg:before {
	content: "\e652";
}

.pricon-perm-scan-wifi:before {
	content: "\e653";
}

.pricon-picture-in-picture:before {
	content: "\e654";
}

.pricon-polymer:before {
	content: "\e655";
}

.pricon-print:before {
	content: "\e656";
}

.pricon-query-builder:before {
	content: "\e657";
}

.pricon-question-answer:before {
	content: "\e658";
}

.pricon-receipt:before {
	content: "\e659";
}

.pricon-redeem:before {
	content: "\e65a";
}

.pricon-reorder:before {
	content: "\e65b";
}

.pricon-report-problem:before {
	content: "\e65c";
}

.pricon-restore:before {
	content: "\e65d";
}

.pricon-room:before {
	content: "\e65e";
}

.pricon-schedule:before {
	content: "\e65f";
}

.pricon-search:before {
	content: "\e660";
}

.pricon-settings:before {
	content: "\e661";
}

.pricon-settings-applications:before {
	content: "\e662";
}

.pricon-settings-backup-restore:before {
	content: "\e663";
}

.pricon-settings-bluetooth:before {
	content: "\e664";
}

.pricon-settings-cell:before {
	content: "\e665";
}

.pricon-settings-display:before {
	content: "\e666";
}

.pricon-settings-ethernet:before {
	content: "\e667";
}

.pricon-settings-input-antenna:before {
	content: "\e668";
}

.pricon-settings-input-component:before {
	content: "\e669";
}

.pricon-settings-input-composite:before {
	content: "\e66a";
}

.pricon-settings-input-hdmi:before {
	content: "\e66b";
}

.pricon-settings-input-svideo:before {
	content: "\e66c";
}

.pricon-settings-overscan:before {
	content: "\e66d";
}

.pricon-settings-phone:before {
	content: "\e66e";
}

.pricon-settings-power:before {
	content: "\e66f";
}

.pricon-settings-remote:before {
	content: "\e670";
}

.pricon-settings-voice:before {
	content: "\e671";
}

.pricon-shop:before {
	content: "\e672";
}

.pricon-shop-two:before {
	content: "\e673";
}

.pricon-shopping-basket:before {
	content: "\e674";
}

.pricon-shopping-cart:before {
	content: "\e675";
}

.pricon-speaker-notes:before {
	content: "\e676";
}

.pricon-spellcheck:before {
	content: "\e677";
}

.pricon-star-rate:before {
	content: "\e678";
}

.pricon-stars:before {
	content: "\e679";
}

.pricon-store:before {
	content: "\e67a";
}

.pricon-subject:before {
	content: "\e67b";
}

.pricon-supervisor-account:before {
	content: "\e67c";
}

.pricon-swap-horiz:before {
	content: "\e67d";
}

.pricon-swap-vert:before {
	content: "\e67e";
}

.pricon-swap-vert-circle:before {
	content: "\e67f";
}

.pricon-system-update-tv:before {
	content: "\e680";
}

.pricon-tab:before {
	content: "\e681";
}

.pricon-tab-unselected:before {
	content: "\e682";
}

.pricon-theaters:before {
	content: "\e683";
}

.pricon-thumb-down:before {
	content: "\e684";
}

.pricon-thumb-up:before {
	content: "\e685";
}

.pricon-thumbs-up-down:before {
	content: "\e686";
}

.pricon-toc:before {
	content: "\e687";
}

.pricon-today:before {
	content: "\e688";
}

.pricon-track-changes:before {
	content: "\e689";
}

.pricon-translate:before {
	content: "\e68a";
}

.pricon-trending-down:before {
	content: "\e68b";
}

.pricon-trending-neutral:before {
	content: "\e68c";
}

.pricon-trending-up:before {
	content: "\e68d";
}

.pricon-turned-in:before {
	content: "\e68e";
}

.pricon-turned-in-not:before {
	content: "\e68f";
}

.pricon-verified-user:before {
	content: "\e690";
}

.pricon-view-agenda:before {
	content: "\e691";
}

.pricon-view-array:before {
	content: "\e692";
}

.pricon-view-carousel:before {
	content: "\e693";
}

.pricon-view-column:before {
	content: "\e694";
}

.pricon-view-day:before {
	content: "\e695";
}

.pricon-view-headline:before {
	content: "\e696";
}

.pricon-view-list:before {
	content: "\e697";
}

.pricon-view-module:before {
	content: "\e698";
}

.pricon-view-quilt:before {
	content: "\e699";
}

.pricon-view-stream:before {
	content: "\e69a";
}

.pricon-view-week:before {
	content: "\e69b";
}

.pricon-visibility:before {
	content: "\e69c";
}

.pricon-visibility-off:before {
	content: "\e69d";
}

.pricon-wallet-giftcard:before {
	content: "\e69e";
}

.pricon-wallet-membership:before {
	content: "\e69f";
}

.pricon-wallet-travel:before {
	content: "\e6a0";
}

.pricon-work:before {
	content: "\e6a1";
}

.pricon-error:before {
	content: "\e6a2";
}

.pricon-warning:before {
	content: "\e6a3";
}

.pricon-album:before {
	content: "\e6a4";
}

.pricon-av-timer:before {
	content: "\e6a5";
}

.pricon-closed-caption:before {
	content: "\e6a6";
}

.pricon-equalizer:before {
	content: "\e6a7";
}

.pricon-explicit:before {
	content: "\e6a8";
}

.pricon-fast-forward:before {
	content: "\e6a9";
}

.pricon-fast-rewind:before {
	content: "\e6aa";
}

.pricon-games:before {
	content: "\e6ab";
}

.pricon-hearing:before {
	content: "\e6ac";
}

.pricon-high-quality:before {
	content: "\e6ad";
}

.pricon-loop:before {
	content: "\e6ae";
}

.pricon-mic:before {
	content: "\e6af";
}

.pricon-mnone:before {
	content: "\e6b0";
}

.pricon-moff:before {
	content: "\e6b1";
}

.pricon-movie:before {
	content: "\e6b2";
}

.pricon-my-library-add:before {
	content: "\e6b3";
}

.pricon-my-library-books:before {
	content: "\e6b4";
}

.pricon-my-library-mus:before {
	content: "\e6b5";
}

.pricon-new-releases:before {
	content: "\e6b6";
}

.pricon-not-interested:before {
	content: "\e6b7";
}

.pricon-pause:before {
	content: "\e6b8";
}

.pricon-pause-circle-fill:before {
	content: "\e6b9";
}

.pricon-pause-circle-outline:before {
	content: "\e6ba";
}

.pricon-play-arrow:before {
	content: "\e6bb";
}

.pricon-play-circle-fill:before {
	content: "\e6bc";
}

.pricon-play-circle-outline:before {
	content: "\e6bd";
}

.pricon-play-shopping-bag:before {
	content: "\e6be";
}

.pricon-playlist-add:before {
	content: "\e6bf";
}

.pricon-queue:before {
	content: "\e6c0";
}

.pricon-queue-mus:before {
	content: "\e6c1";
}

.pricon-radio:before {
	content: "\e6c2";
}

.pricon-recent-actors:before {
	content: "\e6c3";
}

.pricon-repeat:before {
	content: "\e6c4";
}

.pricon-repeat-one:before {
	content: "\e6c5";
}

.pricon-replay:before {
	content: "\e6c6";
}

.pricon-shuffle:before {
	content: "\e6c7";
}

.pricon-skip-next:before {
	content: "\e6c8";
}

.pricon-skip-previous:before {
	content: "\e6c9";
}

.pricon-snooze:before {
	content: "\e6ca";
}

.pricon-stop:before {
	content: "\e6cb";
}

.pricon-subtitles:before {
	content: "\e6cc";
}

.pricon-surround-sound:before {
	content: "\e6cd";
}

.pricon-video-collection:before {
	content: "\e6ce";
}

.pricon-videocam:before {
	content: "\e6cf";
}

.pricon-videocam-off:before {
	content: "\e6d0";
}

.pricon-volume-down:before {
	content: "\e6d1";
}

.pricon-volume-mute:before {
	content: "\e6d2";
}

.pricon-volume-off:before {
	content: "\e6d3";
}

.pricon-volume-up:before {
	content: "\e6d4";
}

.pricon-web:before {
	content: "\e6d5";
}

.pricon-business:before {
	content: "\e6d6";
}

.pricon-call:before {
	content: "\e6d7";
}

.pricon-call-end:before {
	content: "\e6d8";
}

.pricon-call-made:before {
	content: "\e6d9";
}

.pricon-call-merge:before {
	content: "\e6da";
}

.pricon-call-missed:before {
	content: "\e6db";
}

.pricon-call-received:before {
	content: "\e6dc";
}

.pricon-call-split:before {
	content: "\e6dd";
}

.pricon-chat:before {
	content: "\e6de";
}

.pricon-clear-all:before {
	content: "\e6df";
}

.pricon-comment:before {
	content: "\e6e0";
}

.pricon-contacts:before {
	content: "\e6e1";
}

.pricon-dialer-sip:before {
	content: "\e6e2";
}

.pricon-dialpad:before {
	content: "\e6e3";
}

.pricon-dnd-on:before {
	content: "\e6e4";
}

.pricon-email:before {
	content: "\e6e5";
}

.pricon-forum:before {
	content: "\e6e6";
}

.pricon-import-export:before {
	content: "\e6e7";
}

.pricon-invert-colors-off:before {
	content: "\e6e8";
}

.pricon-invert-colors-on:before {
	content: "\e6e9";
}

.pricon-live-help:before {
	content: "\e6ea";
}

.pricon-location-off:before {
	content: "\e6eb";
}

.pricon-location-on:before {
	content: "\e6ec";
}

.pricon-message:before {
	content: "\e6ed";
}

.pricon-messenger:before {
	content: "\e6ee";
}

.pricon-no-sim:before {
	content: "\e6ef";
}

.pricon-phone:before {
	content: "\e6f0";
}

.pricon-portable-wifi-off:before {
	content: "\e6f1";
}

.pricon-quick-contacts-dialer:before {
	content: "\e6f2";
}

.pricon-quick-contacts-mail:before {
	content: "\e6f3";
}

.pricon-ring-volume:before {
	content: "\e6f4";
}

.pricon-stay-current-landscape:before {
	content: "\e6f5";
}

.pricon-stay-current-portrait:before {
	content: "\e6f6";
}

.pricon-stay-primary-landscape:before {
	content: "\e6f7";
}

.pricon-stay-primary-portrait:before {
	content: "\e6f8";
}

.pricon-swap-calls:before {
	content: "\e6f9";
}

.pricon-textsms:before {
	content: "\e6fa";
}

.pricon-voicemail:before {
	content: "\e6fb";
}

.pricon-vpn-key:before {
	content: "\e6fc";
}

.pricon-add:before {
	content: "\e6fd";
}

.pricon-add-box:before {
	content: "\e6fe";
}

.pricon-add-circle:before {
	content: "\e6ff";
}

.pricon-add-circle-outline:before {
	content: "\e700";
}

.pricon-archive:before {
	content: "\e701";
}

.pricon-backspace:before {
	content: "\e702";
}

.pricon-block:before {
	content: "\e703";
}

.pricon-clear:before {
	content: "\e704";
}

.pricon-content-copy:before {
	content: "\e705";
}

.pricon-content-cut:before {
	content: "\e706";
}

.pricon-content-paste:before {
	content: "\e707";
}

.pricon-create:before {
	content: "\e708";
}

.pricon-drafts:before {
	content: "\e709";
}

.pricon-filter-list:before {
	content: "\e70a";
}

.pricon-flag:before {
	content: "\e70b";
}

.pricon-forward:before {
	content: "\e70c";
}

.pricon-gesture:before {
	content: "\e70d";
}

.pricon-inbox:before {
	content: "\e70e";
}

.pricon-link:before {
	content: "\e70f";
}

.pricon-mail:before {
	content: "\e710";
}

.pricon-markunread:before {
	content: "\e711";
}

.pricon-redo:before {
	content: "\e712";
}

.pricon-remove:before {
	content: "\e713";
}

.pricon-remove-circle:before {
	content: "\e714";
}

.pricon-remove-circle-outline:before {
	content: "\e715";
}

.pricon-reply:before {
	content: "\e716";
}

.pricon-reply-all:before {
	content: "\e717";
}

.pricon-report:before {
	content: "\e718";
}

.pricon-save:before {
	content: "\e719";
}

.pricon-select-all:before {
	content: "\e71a";
}

.pricon-send:before {
	content: "\e71b";
}

.pricon-sort:before {
	content: "\e71c";
}

.pricon-text-format:before {
	content: "\e71d";
}

.pricon-undo:before {
	content: "\e71e";
}

.pricon-access-alarm:before {
	content: "\e71f";
}

.pricon-access-alarms:before {
	content: "\e720";
}

.pricon-access-time:before {
	content: "\e721";
}

.pricon-add-alarm:before {
	content: "\e722";
}

.pricon-airplanemode-off:before {
	content: "\e723";
}

.pricon-airplanemode-on:before {
	content: "\e724";
}

.pricon-battery-20:before {
	content: "\e725";
}

.pricon-battery-30:before {
	content: "\e726";
}

.pricon-battery-50:before {
	content: "\e727";
}

.pricon-battery-60:before {
	content: "\e728";
}

.pricon-battery-80:before {
	content: "\e729";
}

.pricon-battery-90:before {
	content: "\e72a";
}

.pricon-battery-alert:before {
	content: "\e72b";
}

.pricon-battery-charging-20:before {
	content: "\e72c";
}

.pricon-battery-charging-30:before {
	content: "\e72d";
}

.pricon-battery-charging-50:before {
	content: "\e72e";
}

.pricon-battery-charging-60:before {
	content: "\e72f";
}

.pricon-battery-charging-80:before {
	content: "\e730";
}

.pricon-battery-charging-90:before {
	content: "\e731";
}

.pricon-battery-charging-full:before {
	content: "\e732";
}

.pricon-battery-full:before {
	content: "\e733";
}

.pricon-battery-std:before {
	content: "\e734";
}

.pricon-battery-unknown:before {
	content: "\e735";
}

.pricon-bluetooth:before {
	content: "\e736";
}

.pricon-bluetooth-connected:before {
	content: "\e737";
}

.pricon-bluetooth-disabled:before {
	content: "\e738";
}

.pricon-bluetooth-searching:before {
	content: "\e739";
}

.pricon-brightness-auto:before {
	content: "\e73a";
}

.pricon-brightness-high:before {
	content: "\e73b";
}

.pricon-brightness-low:before {
	content: "\e73c";
}

.pricon-brightness-medium:before {
	content: "\e73d";
}

.pricon-data-usage:before {
	content: "\e73e";
}

.pricon-developer-mode:before {
	content: "\e73f";
}

.pricon-devices:before {
	content: "\e740";
}

.pricon-dvr:before {
	content: "\e741";
}

.pricon-gps-fixed:before {
	content: "\e742";
}

.pricon-gps-not-fixed:before {
	content: "\e743";
}

.pricon-gps-off:before {
	content: "\e744";
}

.pricon-location-disabled:before {
	content: "\e745";
}

.pricon-location-searching:before {
	content: "\e746";
}

.pricon-multitrack-audio:before {
	content: "\e747";
}

.pricon-network-cell:before {
	content: "\e748";
}

.pricon-network-wifi:before {
	content: "\e749";
}

.pricon-nfc:before {
	content: "\e74a";
}

.pricon-now-wallpaper:before {
	content: "\e74b";
}

.pricon-now-widgets:before {
	content: "\e74c";
}

.pricon-screen-lock-landscape:before {
	content: "\e74d";
}

.pricon-screen-lock-portrait:before {
	content: "\e74e";
}

.pricon-screen-lock-rotation:before {
	content: "\e74f";
}

.pricon-screen-rotation:before {
	content: "\e750";
}

.pricon-sd-storage:before {
	content: "\e751";
}

.pricon-settings-system-daydream:before {
	content: "\e752";
}

.pricon-signal-cellular-0-bar:before {
	content: "\e753";
}

.pricon-signal-cellular-1-bar:before {
	content: "\e754";
}

.pricon-signal-cellular-2-bar:before {
	content: "\e755";
}

.pricon-signal-cellular-3-bar:before {
	content: "\e756";
}

.pricon-signal-cellular-4-bar:before {
	content: "\e757";
}

.pricon-signal-cellular-connected-no-internet-0-bar:before {
	content: "\e758";
}

.pricon-signal-cellular-connected-no-internet-1-bar:before {
	content: "\e759";
}

.pricon-signal-cellular-connected-no-internet-2-bar:before {
	content: "\e75a";
}

.pricon-signal-cellular-connected-no-internet-3-bar:before {
	content: "\e75b";
}

.pricon-signal-cellular-connected-no-internet-4-bar:before {
	content: "\e75c";
}

.pricon-signal-cellular-no-sim:before {
	content: "\e75d";
}

.pricon-signal-cellular-null:before {
	content: "\e75e";
}

.pricon-signal-cellular-off:before {
	content: "\e75f";
}

.pricon-signal-wifi-0-bar:before {
	content: "\e760";
}

.pricon-signal-wifi-1-bar:before {
	content: "\e761";
}

.pricon-signal-wifi-2-bar:before {
	content: "\e762";
}

.pricon-signal-wifi-3-bar:before {
	content: "\e763";
}

.pricon-signal-wifi-4-bar:before {
	content: "\e764";
}

.pricon-signal-wifi-off:before {
	content: "\e765";
}

.pricon-signal-wifi-statusbar-1-bar:before {
	content: "\e766";
}

.pricon-signal-wifi-statusbar-2-bar:before {
	content: "\e767";
}

.pricon-signal-wifi-statusbar-3-bar:before {
	content: "\e768";
}

.pricon-signal-wifi-statusbar-4-bar:before {
	content: "\e769";
}

.pricon-signal-wifi-statusbar-connected-no-internet-1:before {
	content: "\e76a";
}

.pricon-signal-wifi-statusbar-connected-no-internet-2:before {
	content: "\e76b";
}

.pricon-signal-wifi-statusbar-connected-no-internet-3:before {
	content: "\e76c";
}

.pricon-signal-wifi-statusbar-connected-no-internet-4:before {
	content: "\e76d";
}

.pricon-signal-wifi-statusbar-connected-no-internet:before {
	content: "\e76e";
}

.pricon-signal-wifi-statusbar-not-connected:before {
	content: "\e76f";
}

.pricon-signal-wifi-statusbar-null:before {
	content: "\e770";
}

.pricon-storage:before {
	content: "\e771";
}

.pricon-usb:before {
	content: "\e772";
}

.pricon-wifi-lock:before {
	content: "\e773";
}

.pricon-wifi-tethering:before {
	content: "\e774";
}

.pricon-attach-file:before {
	content: "\e775";
}

.pricon-attach-money:before {
	content: "\e776";
}

.pricon-border-all:before {
	content: "\e777";
}

.pricon-border-bottom:before {
	content: "\e778";
}

.pricon-border-clear:before {
	content: "\e779";
}

.pricon-border-color:before {
	content: "\e77a";
}

.pricon-border-horizontal:before {
	content: "\e77b";
}

.pricon-border-inner:before {
	content: "\e77c";
}

.pricon-border-left:before {
	content: "\e77d";
}

.pricon-border-outer:before {
	content: "\e77e";
}

.pricon-border-right:before {
	content: "\e77f";
}

.pricon-border-style:before {
	content: "\e780";
}

.pricon-border-top:before {
	content: "\e781";
}

.pricon-border-vertical:before {
	content: "\e782";
}

.pricon-format-align-center:before {
	content: "\e783";
}

.pricon-format-align-justify:before {
	content: "\e784";
}

.pricon-format-align-left:before {
	content: "\e785";
}

.pricon-format-align-right:before {
	content: "\e786";
}

.pricon-format-bold:before {
	content: "\e787";
}

.pricon-format-clear:before {
	content: "\e788";
}

.pricon-format-color-fill:before {
	content: "\e789";
}

.pricon-format-color-reset:before {
	content: "\e78a";
}

.pricon-format-color-text:before {
	content: "\e78b";
}

.pricon-format-indent-decrease:before {
	content: "\e78c";
}

.pricon-format-indent-increase:before {
	content: "\e78d";
}

.pricon-format-ital:before {
	content: "\e78e";
}

.pricon-format-line-spacing:before {
	content: "\e78f";
}

.pricon-format-list-bulleted:before {
	content: "\e790";
}

.pricon-format-list-numbered:before {
	content: "\e791";
}

.pricon-format-paint:before {
	content: "\e792";
}

.pricon-format-quote:before {
	content: "\e793";
}

.pricon-format-size:before {
	content: "\e794";
}

.pricon-format-strikethrough:before {
	content: "\e795";
}

.pricon-format-textdirection-l-to-r:before {
	content: "\e796";
}

.pricon-format-textdirection-r-to-l:before {
	content: "\e797";
}

.pricon-format-underline:before {
	content: "\e798";
}

.pricon-functions:before {
	content: "\e799";
}

.pricon-insert-chart:before {
	content: "\e79a";
}

.pricon-insert-comment:before {
	content: "\e79b";
}

.pricon-insert-drive-file:before {
	content: "\e79c";
}

.pricon-insert-emoticon:before {
	content: "\e79d";
}

.pricon-insert-invitation:before {
	content: "\e79e";
}

.pricon-insert-link:before {
	content: "\e79f";
}

.pricon-insert-photo:before {
	content: "\e7a0";
}

.pricon-merge-type:before {
	content: "\e7a1";
}

.pricon-mode-comment:before {
	content: "\e7a2";
}

.pricon-mode-edit:before {
	content: "\e7a3";
}

.pricon-publish:before {
	content: "\e7a4";
}

.pricon-vertical-align-bottom:before {
	content: "\e7a5";
}

.pricon-vertical-align-center:before {
	content: "\e7a6";
}

.pricon-vertical-align-top:before {
	content: "\e7a7";
}

.pricon-wrap-text:before {
	content: "\e7a8";
}

.pricon-attachment:before {
	content: "\e7a9";
}

.pricon-cloud:before {
	content: "\e7aa";
}

.pricon-cloud-circle:before {
	content: "\e7ab";
}

.pricon-cloud-done:before {
	content: "\e7ac";
}

.pricon-cloud-download:before {
	content: "\e7ad";
}

.pricon-cloud-off:before {
	content: "\e7ae";
}

.pricon-cloud-queue:before {
	content: "\e7af";
}

.pricon-cloud-upload:before {
	content: "\e7b0";
}

.pricon-file-download:before {
	content: "\e7b1";
}

.pricon-file-upload:before {
	content: "\e7b2";
}

.pricon-folder:before {
	content: "\e7b3";
}

.pricon-folder-open:before {
	content: "\e7b4";
}

.pricon-folder-shared:before {
	content: "\e7b5";
}

.pricon-cast:before {
	content: "\e7b6";
}

.pricon-cast-connected:before {
	content: "\e7b7";
}

.pricon-computer:before {
	content: "\e7b8";
}

.pricon-desktop-mac:before {
	content: "\e7b9";
}

.pricon-desktop-windows:before {
	content: "\e7ba";
}

.pricon-dock:before {
	content: "\e7bb";
}

.pricon-gamepad:before {
	content: "\e7bc";
}

.pricon-headset:before {
	content: "\e7bd";
}

.pricon-headset-m:before {
	content: "\e7be";
}

.pricon-keyboard:before {
	content: "\e7bf";
}

.pricon-keyboard-alt:before {
	content: "\e7c0";
}

.pricon-keyboard-arrow-down:before {
	content: "\e7c1";
}

.pricon-keyboard-arrow-left:before {
	content: "\e7c2";
}

.pricon-keyboard-arrow-right:before {
	content: "\e7c3";
}

.pricon-keyboard-arrow-up:before {
	content: "\e7c4";
}

.pricon-keyboard-backspace:before {
	content: "\e7c5";
}

.pricon-keyboard-capslock:before {
	content: "\e7c6";
}

.pricon-keyboard-control:before {
	content: "\e7c7";
}

.pricon-keyboard-hide:before {
	content: "\e7c8";
}

.pricon-keyboard-return:before {
	content: "\e7c9";
}

.pricon-keyboard-tab:before {
	content: "\e7ca";
}

.pricon-keyboard-voice:before {
	content: "\e7cb";
}

.pricon-laptop:before {
	content: "\e7cc";
}

.pricon-laptop-chromebook:before {
	content: "\e7cd";
}

.pricon-laptop-mac:before {
	content: "\e7ce";
}

.pricon-laptop-windows:before {
	content: "\e7cf";
}

.pricon-memory:before {
	content: "\e7d0";
}

.pricon-mouse:before {
	content: "\e7d1";
}

.pricon-phone-android:before {
	content: "\e7d2";
}

.pricon-phone-iphone:before {
	content: "\e7d3";
}

.pricon-phonelink:before {
	content: "\e7d4";
}

.pricon-phonelink-off:before {
	content: "\e7d5";
}

.pricon-security:before {
	content: "\e7d6";
}

.pricon-sim-card:before {
	content: "\e7d7";
}

.pricon-smartphone:before {
	content: "\e7d8";
}

.pricon-speaker:before {
	content: "\e7d9";
}

.pricon-tablet:before {
	content: "\e7da";
}

.pricon-tablet-android:before {
	content: "\e7db";
}

.pricon-tablet-mac:before {
	content: "\e7dc";
}

.pricon-tv:before {
	content: "\e7dd";
}

.pricon-watch:before {
	content: "\e7de";
}

.pricon-add-to-photos:before {
	content: "\e7df";
}

.pricon-adjust:before {
	content: "\e7e0";
}

.pricon-assistant-photo:before {
	content: "\e7e1";
}

.pricon-audiotrack:before {
	content: "\e7e2";
}

.pricon-blur-circular:before {
	content: "\e7e3";
}

.pricon-blur-linear:before {
	content: "\e7e4";
}

.pricon-blur-off:before {
	content: "\e7e5";
}

.pricon-blur-on:before {
	content: "\e7e6";
}

.pricon-brightness-1:before {
	content: "\e7e7";
}

.pricon-brightness-2:before {
	content: "\e7e8";
}

.pricon-brightness-3:before {
	content: "\e7e9";
}

.pricon-brightness-4:before {
	content: "\e7ea";
}

.pricon-brightness-5:before {
	content: "\e7eb";
}

.pricon-brightness-6:before {
	content: "\e7ec";
}

.pricon-brightness-7:before {
	content: "\e7ed";
}

.pricon-brush:before {
	content: "\e7ee";
}

.pricon-camera:before {
	content: "\e7ef";
}

.pricon-camera-alt:before {
	content: "\e7f0";
}

.pricon-camera-front:before {
	content: "\e7f1";
}

.pricon-camera-rear:before {
	content: "\e7f2";
}

.pricon-camera-roll:before {
	content: "\e7f3";
}

.pricon-center-focus-strong:before {
	content: "\e7f4";
}

.pricon-center-focus-weak:before {
	content: "\e7f5";
}

.pricon-collections:before {
	content: "\e7f6";
}

.pricon-color-lens:before {
	content: "\e7f7";
}

.pricon-colorize:before {
	content: "\e7f8";
}

.pricon-compare:before {
	content: "\e7f9";
}

.pricon-control-point:before {
	content: "\e7fa";
}

.pricon-control-point-duplicate:before {
	content: "\e7fb";
}

.pricon-crop-3-2:before {
	content: "\e7fc";
}

.pricon-crop-5-4:before {
	content: "\e7fd";
}

.pricon-crop-7-5:before {
	content: "\e7fe";
}

.pricon-crop-16-9:before {
	content: "\e7ff";
}

.pricon-crop:before {
	content: "\e800";
}

.pricon-crop-din:before {
	content: "\e801";
}

.pricon-crop-free:before {
	content: "\e802";
}

.pricon-crop-landscape:before {
	content: "\e803";
}

.pricon-crop-original:before {
	content: "\e804";
}

.pricon-crop-portrait:before {
	content: "\e805";
}

.pricon-crop-square:before {
	content: "\e806";
}

.pricon-dehaze:before {
	content: "\e807";
}

.pricon-details:before {
	content: "\e808";
}

.pricon-edit:before {
	content: "\e809";
}

.pricon-exposure:before {
	content: "\e80a";
}

.pricon-exposure-minus-1:before {
	content: "\e80b";
}

.pricon-exposure-minus-2:before {
	content: "\e80c";
}

.pricon-exposure-plus-1:before {
	content: "\e80d";
}

.pricon-exposure-plus-2:before {
	content: "\e80e";
}

.pricon-exposure-zero:before {
	content: "\e80f";
}

.pricon-filter-1:before {
	content: "\e810";
}

.pricon-filter-2:before {
	content: "\e811";
}

.pricon-filter-3:before {
	content: "\e812";
}

.pricon-filter-4:before {
	content: "\e813";
}

.pricon-filter-5:before {
	content: "\e814";
}

.pricon-filter-6:before {
	content: "\e815";
}

.pricon-filter-7:before {
	content: "\e816";
}

.pricon-filter-8:before {
	content: "\e817";
}

.pricon-filter-9:before {
	content: "\e818";
}

.pricon-filter-9-plus:before {
	content: "\e819";
}

.pricon-filter:before {
	content: "\e81a";
}

.pricon-filter-b-and-w:before {
	content: "\e81b";
}

.pricon-filter-center-focus:before {
	content: "\e81c";
}

.pricon-filter-drama:before {
	content: "\e81d";
}

.pricon-filter-frames:before {
	content: "\e81e";
}

.pricon-filter-hdr:before {
	content: "\e81f";
}

.pricon-filter-none:before {
	content: "\e820";
}

.pricon-filter-tilt-shift:before {
	content: "\e821";
}

.pricon-filter-vintage:before {
	content: "\e822";
}

.pricon-flare:before {
	content: "\e823";
}

.pricon-flash-auto:before {
	content: "\e824";
}

.pricon-flash-off:before {
	content: "\e825";
}

.pricon-flash-on:before {
	content: "\e826";
}

.pricon-flip:before {
	content: "\e827";
}

.pricon-gradient:before {
	content: "\e828";
}

.pricon-grain:before {
	content: "\e829";
}

.pricon-grid-off:before {
	content: "\e82a";
}

.pricon-grid-on:before {
	content: "\e82b";
}

.pricon-hdr-off:before {
	content: "\e82c";
}

.pricon-hdr-on:before {
	content: "\e82d";
}

.pricon-hdr-strong:before {
	content: "\e82e";
}

.pricon-hdr-weak:before {
	content: "\e82f";
}

.pricon-healing:before {
	content: "\e830";
}

.pricon-image:before {
	content: "\e831";
}

.pricon-image-aspect-ratio:before {
	content: "\e832";
}

.pricon-iso:before {
	content: "\e833";
}

.pricon-landscape:before {
	content: "\e834";
}

.pricon-leak-add:before {
	content: "\e835";
}

.pricon-leak-remove:before {
	content: "\e836";
}

.pricon-lens:before {
	content: "\e837";
}

.pricon-looks-3:before {
	content: "\e838";
}

.pricon-looks-4:before {
	content: "\e839";
}

.pricon-looks-5:before {
	content: "\e83a";
}

.pricon-looks-6:before {
	content: "\e83b";
}

.pricon-looks:before {
	content: "\e83c";
}

.pricon-looks-one:before {
	content: "\e83d";
}

.pricon-looks-two:before {
	content: "\e83e";
}

.pricon-loupe:before {
	content: "\e83f";
}

.pricon-movie-creation:before {
	content: "\e840";
}

.pricon-nature:before {
	content: "\e841";
}

.pricon-nature-people:before {
	content: "\e842";
}

.pricon-navigate-before:before {
	content: "\e843";
}

.pricon-navigate-next:before {
	content: "\e844";
}

.pricon-palette:before {
	content: "\e845";
}

.pricon-panorama:before {
	content: "\e846";
}

.pricon-panorama-fisheye:before {
	content: "\e847";
}

.pricon-panorama-horizontal:before {
	content: "\e848";
}

.pricon-panorama-vertical:before {
	content: "\e849";
}

.pricon-panorama-wide-angle:before {
	content: "\e84a";
}

.pricon-photo:before {
	content: "\e84b";
}

.pricon-photo-album:before {
	content: "\e84c";
}

.pricon-photo-camera:before {
	content: "\e84d";
}

.pricon-photo-library:before {
	content: "\e84e";
}

.pricon-portrait:before {
	content: "\e84f";
}

.pricon-remove-red-eye:before {
	content: "\e850";
}

.pricon-rotate-left:before {
	content: "\e851";
}

.pricon-rotate-right:before {
	content: "\e852";
}

.pricon-slideshow:before {
	content: "\e853";
}

.pricon-straighten:before {
	content: "\e854";
}

.pricon-style:before {
	content: "\e855";
}

.pricon-switch-camera:before {
	content: "\e856";
}

.pricon-switch-video:before {
	content: "\e857";
}

.pricon-tag-faces:before {
	content: "\e858";
}

.pricon-texture:before {
	content: "\e859";
}

.pricon-timelapse:before {
	content: "\e85a";
}

.pricon-timer-3:before {
	content: "\e85b";
}

.pricon-timer-10:before {
	content: "\e85c";
}

.pricon-timer:before {
	content: "\e85d";
}

.pricon-timer-auto:before {
	content: "\e85e";
}

.pricon-timer-off:before {
	content: "\e85f";
}

.pricon-tonality:before {
	content: "\e860";
}

.pricon-transform:before {
	content: "\e861";
}

.pricon-tune:before {
	content: "\e862";
}

.pricon-wb-auto:before {
	content: "\e863";
}

.pricon-wb-cloudy:before {
	content: "\e864";
}

.pricon-wb-incandescent:before {
	content: "\e865";
}

.pricon-wb-irradescent:before {
	content: "\e866";
}

.pricon-wb-sunny:before {
	content: "\e867";
}

.pricon-beenhere:before {
	content: "\e868";
}

.pricon-directions:before {
	content: "\e869";
}

.pricon-directions-bike:before {
	content: "\e86a";
}

.pricon-directions-bus:before {
	content: "\e86b";
}

.pricon-directions-car:before {
	content: "\e86c";
}

.pricon-directions-ferry:before {
	content: "\e86d";
}

.pricon-directions-subway:before {
	content: "\e86e";
}

.pricon-directions-train:before {
	content: "\e86f";
}

.pricon-directions-transit:before {
	content: "\e870";
}

.pricon-directions-walk:before {
	content: "\e871";
}

.pricon-flight:before {
	content: "\e872";
}

.pricon-hotel:before {
	content: "\e873";
}

.pricon-layers:before {
	content: "\e874";
}

.pricon-layers-clear:before {
	content: "\e875";
}

.pricon-local-airport:before {
	content: "\e876";
}

.pricon-local-atm:before {
	content: "\e877";
}

.pricon-local-attraction:before {
	content: "\e878";
}

.pricon-local-bar:before {
	content: "\e879";
}

.pricon-local-cafe:before {
	content: "\e87a";
}

.pricon-local-car-wash:before {
	content: "\e87b";
}

.pricon-local-convenience-store:before {
	content: "\e87c";
}

.pricon-local-drink:before {
	content: "\e87d";
}

.pricon-local-florist:before {
	content: "\e87e";
}

.pricon-local-gas-station:before {
	content: "\e87f";
}

.pricon-local-grocery-store:before {
	content: "\e880";
}

.pricon-local-hospital:before {
	content: "\e881";
}

.pricon-local-hotel:before {
	content: "\e882";
}

.pricon-local-laundry-service:before {
	content: "\e883";
}

.pricon-local-library:before {
	content: "\e884";
}

.pricon-local-mall:before {
	content: "\e885";
}

.pricon-local-movies:before {
	content: "\e886";
}

.pricon-local-offer:before {
	content: "\e887";
}

.pricon-local-parking:before {
	content: "\e888";
}

.pricon-local-pharmacy:before {
	content: "\e889";
}

.pricon-local-phone:before {
	content: "\e88a";
}

.pricon-local-pizza:before {
	content: "\e88b";
}

.pricon-local-play:before {
	content: "\e88c";
}

.pricon-local-post-office:before {
	content: "\e88d";
}

.pricon-local-print-shop:before {
	content: "\e88e";
}

.pricon-local-restaurant:before {
	content: "\e88f";
}

.pricon-local-see:before {
	content: "\e890";
}

.pricon-local-shipping:before {
	content: "\e891";
}

.pricon-local-taxi:before {
	content: "\e892";
}

.pricon-location-history:before {
	content: "\e893";
}

.pricon-map:before {
	content: "\e894";
}

.pricon-my-location:before {
	content: "\e895";
}

.pricon-navigation:before {
	content: "\e896";
}

.pricon-pin-drop:before {
	content: "\e897";
}

.pricon-place:before {
	content: "\e898";
}

.pricon-rate-review:before {
	content: "\e899";
}

.pricon-restaurant-menu:before {
	content: "\e89a";
}

.pricon-satellite:before {
	content: "\e89b";
}

.pricon-store-mall-directory:before {
	content: "\e89c";
}

.pricon-terrain:before {
	content: "\e89d";
}

.pricon-traff:before {
	content: "\e89e";
}

.pricon-apps:before {
	content: "\e89f";
}

.pricon-arrow-back:before {
	content: "\e8a0";
}

.pricon-arrow-drop-down:before {
	content: "\e8a1";
}

.pricon-arrow-drop-down-circle:before {
	content: "\e8a2";
}

.pricon-arrow-drop-up:before {
	content: "\e8a3";
}

.pricon-arrow-forward:before {
	content: "\e8a4";
}

.pricon-cancel:before {
	content: "\e8a5";
}

.pricon-check:before {
	content: "\e8a6";
}

.pricon-chevron-left:before {
	content: "\e8a7";
}

.pricon-chevron-right:before {
	content: "\e8a8";
}

.pricon-close:before {
	content: "\e8a9";
}

.pricon-expand-less:before {
	content: "\e8aa";
}

.pricon-expand-more:before {
	content: "\e8ab";
}

.pricon-fullscreen:before {
	content: "\e8ac";
}

.pricon-fullscreen-exit:before {
	content: "\e8ad";
}

.pricon-menu:before {
	content: "\e8ae";
}

.pricon-more-horiz:before {
	content: "\e8af";
}

.pricon-more-vert:before {
	content: "\e8b0";
}

.pricon-refresh:before {
	content: "\e8b1";
}

.pricon-unfold-less:before {
	content: "\e8b2";
}

.pricon-unfold-more:before {
	content: "\e8b3";
}

.pricon-adb:before {
	content: "\e8b4";
}

.pricon-bluetooth-audio:before {
	content: "\e8b5";
}

.pricon-disc-full:before {
	content: "\e8b6";
}

.pricon-dnd-forwardslash:before {
	content: "\e8b7";
}

.pricon-do-not-disturb:before {
	content: "\e8b8";
}

.pricon-drive-eta:before {
	content: "\e8b9";
}

.pricon-event-available:before {
	content: "\e8ba";
}

.pricon-event-busy:before {
	content: "\e8bb";
}

.pricon-event-note:before {
	content: "\e8bc";
}

.pricon-folder-special:before {
	content: "\e8bd";
}

.pricon-mms:before {
	content: "\e8be";
}

.pricon-more:before {
	content: "\e8bf";
}

.pricon-network-locked:before {
	content: "\e8c0";
}

.pricon-phone-bluetooth-speaker:before {
	content: "\e8c1";
}

.pricon-phone-forwarded:before {
	content: "\e8c2";
}

.pricon-phone-in-talk:before {
	content: "\e8c3";
}

.pricon-phone-locked:before {
	content: "\e8c4";
}

.pricon-phone-missed:before {
	content: "\e8c5";
}

.pricon-phone-paused:before {
	content: "\e8c6";
}

.pricon-play-download:before {
	content: "\e8c7";
}

.pricon-play-install:before {
	content: "\e8c8";
}

.pricon-sd-card:before {
	content: "\e8c9";
}

.pricon-sim-card-alert:before {
	content: "\e8ca";
}

.pricon-sms:before {
	content: "\e8cb";
}

.pricon-sms-failed:before {
	content: "\e8cc";
}

.pricon-sync:before {
	content: "\e8cd";
}

.pricon-sync-disabled:before {
	content: "\e8ce";
}

.pricon-sync-problem:before {
	content: "\e8cf";
}

.pricon-system-update:before {
	content: "\e8d0";
}

.pricon-tap-and-play:before {
	content: "\e8d1";
}

.pricon-time-to-leave:before {
	content: "\e8d2";
}

.pricon-vibration:before {
	content: "\e8d3";
}

.pricon-voice-chat:before {
	content: "\e8d4";
}

.pricon-vpn-lock:before {
	content: "\e8d5";
}

.pricon-cake:before {
	content: "\e8d6";
}

.pricon-domain:before {
	content: "\e8d7";
}

.pricon-group:before {
	content: "\e8d8";
}

.pricon-group-add:before {
	content: "\e8d9";
}

.pricon-location-city:before {
	content: "\e8da";
}

.pricon-mood:before {
	content: "\e8db";
}

.pricon-notifications:before {
	content: "\e8dc";
}

.pricon-notifications-none:before {
	content: "\e8dd";
}

.pricon-notifications-off:before {
	content: "\e8de";
}

.pricon-notifications-on:before {
	content: "\e8df";
}

.pricon-notifications-paused:before {
	content: "\e8e0";
}

.pricon-pages:before {
	content: "\e8e1";
}

.pricon-party-mode:before {
	content: "\e8e2";
}

.pricon-people:before {
	content: "\e8e3";
}

.pricon-people-outline:before {
	content: "\e8e4";
}

.pricon-person:before {
	content: "\e8e5";
}

.pricon-person-add:before {
	content: "\e8e6";
}

.pricon-person-outline:before {
	content: "\e8e7";
}

.pricon-plus-one:before {
	content: "\e8e8";
}

.pricon-poll:before {
	content: "\e8e9";
}

.pricon-publ:before {
	content: "\e8ea";
}

.pricon-school:before {
	content: "\e8eb";
}

.pricon-share:before {
	content: "\e8ec";
}

.pricon-whatshot:before {
	content: "\e8ed";
}

.pricon-check-box:before {
	content: "\e8ee";
}

.pricon-check-box-outline-blank:before {
	content: "\e8ef";
}

.pricon-radio-button-off:before {
	content: "\e8f0";
}

.pricon-radio-button-on:before {
	content: "\e8f1";
}

.pricon-star:before {
	content: "\e8f2";
}

.pricon-star-half:before {
	content: "\e8f3";
}

.pricon-star-outline:before {
	content: "\e8f4";
}

